import React from "react"
import { Helmet } from "react-helmet"
import Layout from "../../components/Layout"
import 'bootstrap/dist/css/bootstrap.min.css';
import TumericChiliHomepage from "../../components/products-tumeric-chili/TumericChiliHomepage";

export default function Home() {
  return (
    <Layout>
      <section>
        <div>
          {/* For page head */}
          <Helmet>
                <title>Maker Recipe Sdn. Bhd.-Tumeric & Chili</title>
          </Helmet>

          <TumericChiliHomepage />
        </div>
      </section>
    </Layout>
    
  )
}